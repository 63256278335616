import { PrismicRichText } from '@prismicio/react';
import { AddToButtons } from 'components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import * as callToActionSectionClasses from './callToActionSection.module.scss';


interface ICallToActionSectionProps {
  slice: any;
}

export const CallToActionSection: React.FunctionComponent<ICallToActionSectionProps> = ({ slice }) => {

  return (
    <div className={`container text-center d-flex flex-column justify-content-center py-24 ${callToActionSectionClasses.CallToActionSection}`}>
      <div className={`mx-auto d-flex flex-column justify-content-between ${callToActionSectionClasses.Intro_Header}`}>

        <GatsbyImage
          className='mx-auto'
          image={slice.primary.icon_image.gatsbyImageData}
          alt={slice.primary.icon_image.alt}
          objectFit='contain' />

        <div>
          <PrismicRichText
            field={slice.primary.title.richText}
            components={{
              heading1: (props: any) => <h1 className={`${callToActionSectionClasses.Header_Text}`}>{props.children}</h1>,
            }} />
          <div className="subheader">
            <PrismicRichText
              field={slice.primary.paragraph.richText}
              components={{
                paragraph: (props: any) => <h1 className={`${callToActionSectionClasses.Subheader_Text}`}>{props.children}</h1>,
              }} />
          </div>
        </div>

        <ul className="list-unstyled">
          <AddToButtons
            addToText
            parentComponent='add-to-page'
            data={[slice.primary]} />
        </ul>
      </div>
      <p className='Font_Color_Quaternary'>
        {slice.primary.copyright_text}
      </p>
    </div>
  );
};


export const query = graphql`
  fragment CallToActionSectionData on PrismicTeamblePageDataBodyCallToAction {
    primary {
      icon_image {
        gatsbyImageData(layout: FIXED, placeholder: BLURRED)
        alt
      }
      title {
        richText
      }
      paragraph {
        richText
      }
      copyright_text
      app_name
      app_logo {
        alt
        gatsbyImageData(layout: FIXED, placeholder: BLURRED)
      }
      link_to_page {
        link_type
        type
        uid
        url
      }
    }
  }
`;
import { PrismicRichText } from '@prismicio/react';
import { graphql } from 'gatsby';
import * as React from 'react';
import * as videoSectionClasses from './videoSection.module.scss';

interface IVideoSectionProps {
  slice: any;
}

export const VideoSection: React.FunctionComponent<IVideoSectionProps> = ({ slice }) => {
  return (
    <section className={`video-section section ${videoSectionClasses.VideoSection}`} id="why-teamble-section">
      <div className="container">

        <PrismicRichText
          field={slice.primary.video_section_title.richText}
          components={{
            heading2: (props: any) => <h2 className="mb-10 Font_Color_Quinary Lato_65_Bolder">{props.children}</h2>,
          }} />

        <div className={`video-section-content ${videoSectionClasses.VideoSection_Content}`}>
          <div dangerouslySetInnerHTML={{ __html: slice.primary.youtube_video.html }} />
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment VideoSectionData on PrismicPageDataBodyTeambleVideo {
    primary {
      video_section_title {
        richText
      }
      youtube_video {
        html
      }
    }
  }
`;
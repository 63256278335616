import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import * as quoteSectionClasses from './quoteSection.module.scss';

interface IQuoteSectionProps {
  slice: any;
}

export const QuoteSection: React.FunctionComponent<IQuoteSectionProps> = ({ slice }) => {
  return (
    <section className={`quote-section section ${quoteSectionClasses.QuoteSection}`}>
      <div className="container">
        <div className={
          `text-white p-12 pt-lg-42 px-lg-42 pb-lg-30 position-relative ${quoteSectionClasses.QuoteSection_Content}`
        }>
          <GatsbyImage
            className={`position-absolute end-0 bottom-0 ${quoteSectionClasses.Professor_Image}`}
            image={slice.primary.quoter_image.gatsbyImageData}
            alt={slice.primary.quoter_image.alt}
            objectFit='contain' />

          <p className={`Lato_14_Regular opacity-50`}>
            {slice.primary.quote_section_description}
          </p>

          <div className={`${quoteSectionClasses.QuoteSection_Row}`}>
            <p className={`Lato_52_Bolder position-relative mb-20 mt-24 ${quoteSectionClasses.QuoteSection_QuoteText}`}>
              {slice.primary.quote}
            </p>
            <p className={`Lato_32_Bolder mb-6 ${quoteSectionClasses.Quote_Professor_Name}`}>
              {slice.primary.quoter_name}
            </p>
            <p className={`Lato_28_Semibold opacity-50 mt-0 mb-30 ${quoteSectionClasses.Quote_Professor_Title}`}>
              {slice.primary.quoter_title}
            </p>
          </div>

          <div className={`d-flex justify-content-between flex-wrap flex-lg-nowrap ${quoteSectionClasses.Quote_Professor_Description}`}>
            {
              slice.items.map(
                (item: any, index: number) => {
                  return (
                    <p className='Lato_20_Semibold mb-5' key={index}>{item.quoter_description}</p>
                  )
                }
              )
            }
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment QuoteSectionData on PrismicPageDataBodyQuoteSection {
    primary {
      quote
      quoter_title
      quoter_name
      quote_section_description
      quoter_image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        alt
      }
    }
    items {
      quoter_description
    }
  }
`;
import { PricingTableSection } from './pricingTableSection/pricingTableSection';
import { ClientTestimonials } from './clientTestimonials/clientTestimonials';
import { CustomerLogosSection } from './customerLogosSection/customerLogosSection';
import { DoItAllSection } from './doItAllSection/doItAllSection';
import { FooterLinks } from './footer/footerLinks';
import { HeroSection } from './heroSection/heroSection';
import { HowItWorksSection } from './howItWorksSection/howItWorksSection';
import { QuoteSection } from './quoteSection/quoteSection';
import { VideoSection } from './videoSection/videoSection';
import { FaqSection } from './faqSection/faqSection';
import { CallToActionSection } from './callToActionSection/callToActionSection';
import { BlogDetailsSection } from './blogDetailsSection/blogDetailsSection';
import { AddressSection } from './addressSection/addressSection';

export { default as AddToButtons } from './addToButtons/addToButtons';
export { default as Footer } from './footer/footer';
export { default as Header } from './header';
export { default as Layout } from './layout';
export { default as NavigationBar } from './navigationBar/navigationBar';
export { default as SEO } from './seo';

export const sliceComponents = {
  footer_links: FooterLinks,
  hero_section: HeroSection,
  customer_logos: CustomerLogosSection,
  client_testimonials: ClientTestimonials,
  teamble_video: VideoSection,
  quote_section: QuoteSection,
  how_it_works_section: HowItWorksSection,
  slack___ms_teams_section: DoItAllSection,
  pricing_table: PricingTableSection,
  faq_section: FaqSection,
  call_to_action: CallToActionSection,
  blog: BlogDetailsSection,
  address: AddressSection
};
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import * as clientTestimonialsClasses from './clientTestimonials.module.scss';

interface IClientTestimonialsProps {
  slice: any;
}

export const ClientTestimonials: React.FunctionComponent<IClientTestimonialsProps> = (
  { slice }
) => {


  return (
    <section className={`section mt--210 ${clientTestimonialsClasses.ClientTestimonialsSection}`}>
      <div className="container">
        <div className="row client-testimonial-cards">
          {
            slice.items.map(
              (item: any, index: number) => (
                <div key={index} className={`col-12 col-lg-6 p-14 position-relative d-flex flex-column justify-content-between ${clientTestimonialsClasses.ClientTestimonial_Card}`}>
                  <p className="mt-0 mb-12 Font_Color_Tertiary Lato_22_Semibold client-testimonial-text">
                    {item.detailed_testimonial}
                  </p>
                  <div className="d-flex justify-content-between align-content-center flex-wrap client-details-row">
                    <div className="flex-shrink-0 d-flex align-items-center me-8 client-details">
                      <GatsbyImage
                        className='me-8 rounded-circle'
                        image={item.client_image.gatsbyImageData}
                        alt={item.client_image.alt}
                        objectFit='contain' />
                      <div>
                        <p className="Lato_20_Bold Font_Color_Quaternary mt-0 mb-3 client-name">{item.client_name}</p>
                        <p className="Lato_20_Regular Font_Color_Quaternary m-0 client-title">{item.client_designation}</p>
                      </div>
                    </div>
                    <div className="flex-shrink-0 mt-12 client-logo">
                      <GatsbyImage
                        image={item.client_company_logo.gatsbyImageData}
                        alt={item.client_company_logo.alt}
                        objectFit='contain' />
                    </div>
                  </div>
                </div>
              )
            )
          }
        </div>
      </div>
    </section>
  );
};


export const query = graphql`
  fragment ClientTestimonialsData on PrismicPageDataBodyClientTestimonials {
    items {
      client_name
      client_designation
      client_image {
        gatsbyImageData(layout: FIXED, placeholder: BLURRED)
        alt
      }
      client_company_logo {
        gatsbyImageData(layout: FIXED, placeholder: BLURRED)
        alt
      }
      detailed_testimonial
    }
  }
`;
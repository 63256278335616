import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { Helmet } from "react-helmet"

interface ISEOProps {
  title: string;
  description?: string,
  lang?: string,
  meta?: Array<{ name: string, content: string }>,
}

const SEO: React.FunctionComponent<ISEOProps> =
  ({ description = '', lang = 'en', meta = [], title }) => {

    const { site }: any = useStaticQuery(
      graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
    );

    const defaultTitle: string = site.siteMetadata?.title

    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: '',
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata?.author || ``,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: '',
          },
        ].concat(meta)}
      />
    )
  }

export default SEO;

// extracted by mini-css-extract-plugin
export var PricingSection = "pricingTableSection-module--PricingSection--ORPuB";
export var PricePlanColumn = "pricingTableSection-module--PricePlanColumn--9NR5Z";
export var ScheduleDemo_Button = "pricingTableSection-module--ScheduleDemo_Button--M0LFq";
export var Features_Title = "pricingTableSection-module--Features_Title--DsCdP";
export var One_Month_Free_Text = "pricingTableSection-module--One_Month_Free_Text--WtqRN";
export var Includes_Title = "pricingTableSection-module--Includes_Title--lOyqa";
export var Includes_Text = "pricingTableSection-module--Includes_Text--E85z5";
export var mdSmallSize100 = "pricingTableSection-module--md-small-size-100--wMfJ2";
export var container = "pricingTableSection-module--container--n+JqX";
export var mdGutter = "pricingTableSection-module--md-gutter--IHziY";
export var Plan_Price = "pricingTableSection-module--Plan_Price--xlD+n";
export var Plan_Type = "pricingTableSection-module--Plan_Type--FYe5Q";
import { PrismicRichText, PrismicText } from '@prismicio/react';
import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import * as pricingTableSectionClasses from './pricingTableSection.module.scss';
import slackLogo from '../../images/slack-logo.svg';
import teamsLogo from '../../images/ms-teams-logo.svg';

interface IPricingTableSectionProps {
  slice: any;
}

interface IPricePlanColumnProps {
  columnData: any;
}

interface ICallToActionsProps {
  plan_type: string;
}


const CallToActions: React.FC<ICallToActionsProps> = ({ plan_type }) => {

  switch (plan_type) {

    case 'Base':
    case 'Team': {
      return (
        <>
          <Link to='/add-to-slack' className='nav-link mb-6 p-0'>
            <Button className='d-inline-flex align-items-center bg-white Lato_16_Bolder AddToButton slack py-8 px-24 Parent_pricing'>
              <span className='me-3'> Add to </span>
              <img src={slackLogo} alt="Slack Logo" />
              <span className='ms-3'>Slack</span>
            </Button>
          </Link>
          <Link to='/add-to-teams' className='nav-link mb-6 p-0'>
            <Button className='d-inline-flex align-items-center bg-white Lato_16_Bolder AddToButton teams py-8 px-24 Parent_pricing'>
              <span className='me-3'> Add to </span>
              <img src={teamsLogo} alt="MS Teams Logo" />
              <span className='ms-3'>Teams</span>
            </Button>
          </Link>
        </>
      );
    }

    case 'Enterprise': {
      return (
        <a href="https://calendly.com/teamble/30min" target='_blank'>
          <Button className={`${pricingTableSectionClasses.ScheduleDemo_Button}`}>Schedule a Demo</Button>
        </a>
      );
    }

    default: {
      return null;
    }

  }
};


const PricePlanColumn: React.FC<IPricePlanColumnProps> = ({ columnData }) => {

  const [planPrice, setPlanPrice] = React.useState<string>('');


  React.useEffect(
    () => {

      switch (columnData.plan_type) {

        case 'Base': {
          setPlanPrice('Free');
          break;
        }

        case 'Team': {
          setPlanPrice('$5 /');
          break;
        }

        case 'Enterprise': {
          setPlanPrice('Custom');
          break;
        }

      }

    },
    [columnData]
  );


  return (
    <div
      className={`col-12 col-lg-4 gx-5 ${pricingTableSectionClasses.PricePlanColumn}`}
      style={{ backgroundColor: columnData.plan_type === 'Team' ? '#f0f1f2' : '#fff' }}>

      <PrismicRichText
        field={columnData.plan_title.richText}
        components={{
          heading3: (props: any) => <h3 className='my-8 Lato_30_Bold text-center'>{props.children}</h3>,
        }} />

      <p className="Montserrat_20_Medium text-center">
        {columnData.plan_description}
      </p>

      <div className={`d-flex justify-content-center flex-column my-30 ${pricingTableSectionClasses.Plan_Price}`}>
        <h2 className='Lato_45_Regular d-flex justify-content-center align-items-center'>
          {planPrice}
          {
            columnData.plan_type === 'Team' &&
            (
              <span className='ms-5 Lato_16_Regular d-inline-flex'>
                <span>per user <br /> per month</span>
              </span>
            )
          }
        </h2>
        {
          columnData.plan_type === 'Team' &&
          <p className={`${pricingTableSectionClasses.One_Month_Free_Text} text-center fw-bold`}> One Month Free </p>
        }
      </div>

      <div className={`d-flex flex-column align-items-center justify-content-center ${pricingTableSectionClasses.Plan_Type}`}>
        <CallToActions plan_type={columnData.plan_type} />
      </div>

      <p className={`${pricingTableSectionClasses.Features_Title} text-center`}>
        {columnData.features_title}
      </p>

      <PrismicRichText
        field={columnData.features.richText}
        components={{
          strong: (props: any) => <strong className={`${pricingTableSectionClasses.Includes_Title}`}>{props.children}</strong>,
          list: (props: any) => <ul className='list-unstyled'>{props.children}</ul>,
          listItem: (props: any) => (
            <li className={`${pricingTableSectionClasses.Includes_Text}`}>
              <span style={{ color: '#23a8f5' }}>✔ </span>{props.children}
            </li>
          ),
        }} />
    </div>
  );

};


export const PricingTableSection: React.FunctionComponent<IPricingTableSectionProps> = ({ slice }) => {

  return (
    <div className={`py-30 Font_Color_Primary ${pricingTableSectionClasses.PricingSection}`} id="pricing">

      <div className="d-flex flex-wrap mt-10">

        <div className="container">
          <PrismicRichText
            field={slice.primary.section_title.richText}
            components={{
              heading2: (props: any) => <h2 className="Lato_36_Bold mb-10 text-center">{props.children}</h2>,
            }} />
        </div>

        <div className="container">
          <div className="row">
            {
              slice.items.map(
                (item: any, index: number) => {

                  return (
                    <PricePlanColumn key={index} columnData={item} />
                  )
                }
              )
            }
          </div>
        </div>

      </div>
    </div>
  );
};

export const query = graphql`
  fragment PricingTableSectionData on PrismicTeamblePageDataBodyPricingTable {
    primary {
      section_title {
        richText
      }
    }
    items {
      features {
        richText
      }
      features_title
      plan_description
      plan_title {
        richText
      }
      plan_type
      team_plan_price
    }
  }
`;
import { graphql, useStaticQuery } from "gatsby";

export const useNavigationData = () => {

  return useStaticQuery(
    graphql`
      {
        prismicNavigation {
          data {
            home_page_background_image {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
              alt
            }
            teamble_white_logo {
              gatsbyImageData(layout: FIXED)
              alt
            }
            teamble_gradient_logo {
              gatsbyImageData(layout: FIXED)
              alt
            }
            links_group {
              link_label
              fragment_id
              link {
                link_type
                url
                uid
                type
              }
            }
            add_to_app_links {
              app_logo {
                gatsbyImageData(layout: FIXED, placeholder: BLURRED)
                alt
              }
              app_name
              link_to_page {
                url
                uid
                type
                link_type
              }
            }
          }
        }
      }
    `
  ).prismicNavigation.data;
};
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import * as heroSectionClasses from './heroSection.module.scss';
import msTeamsBannerImage from './../../images/ms-teams-banner.svg';
import slackBannerImage from './../../images/slack-banner.svg';
import { PrismicLink, PrismicRichText } from '@prismicio/react';
import { graphql } from 'gatsby';
import { AddToButtons } from 'components';
import { Button } from 'react-bootstrap';


interface IHeroSectionProps {
  slice: any;
}

interface IBannerSectionProps {

}

const BannerSection: React.FC<IBannerSectionProps> = () => {
  return (
    <div className={`d-flex justify-content-start justify-content-lg-center flex-wrap mt-30 mt-lg-90 ${heroSectionClasses.HeroSection_Badges}`}>

      <div className="flex-shrink-0 me-6 mb-6">
        <a
          href="https://www.producthunt.com/posts/teamble?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-teamble"
          target="_blank"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=321936&theme=light&period=daily"
            alt="Teamble - Simple, continuous, and actionable feedback on Slack & Teams | Product Hunt"
          />
        </a>
      </div>

      <div className="flex-shrink-0 me-6 mb-6">
        <a
          href="https://teamble.notion.site/teamble-is-a-Featured-App-on-both-Teams-and-Slack-App-Stores-8f55a5e910994e90b6aeccc5e7a3658e"
          target="_blank"
        >
          <img
            src={msTeamsBannerImage}
            alt="Teamble - MS Teams banner"
          />
        </a>
      </div>

      <div className="flex-shrink-0 me-6 mb-6">
        <a
          href="https://teamble.notion.site/teamble-is-a-Featured-App-on-both-Teams-and-Slack-App-Stores-8f55a5e910994e90b6aeccc5e7a3658e"
          target="_blank"
        >
          <img
            src={slackBannerImage}
            alt="Teamble - Slack banner"
          />
        </a>
      </div>

    </div>
  );
};


const ButtonComponents = ({ items }: any) => {

  const [scheduleDemo, ...data] = items;
  const parentComponent = "blue-nav";

  return (
    <>
      <div>
        <PrismicLink field={scheduleDemo.link_to_page} className={`nav-link p-0 me-10 mb-6`}>
          <Button
            style={{ width: "378px" }}
            className={
              `d-inline-flex align-items-center justify-content-center bg-white Lato_16_Bolder AddToButton py-8 px-24
            ${scheduleDemo.app_name.split(' ').join('-').toLowerCase()}
            Parent_${parentComponent}`}
          >
            <span className='ms-3'>{scheduleDemo.app_name}</span>
          </Button>
        </PrismicLink>
      </div>

      <div style={{ display: "flex", alignItems: "center" }} >
        {
          data.map(
            (item: any, index: number) => {
              return (
                <div key={index}>
                  <PrismicLink field={item.link_to_page} className={`nav-link p-0 me-4 mb-6`}>
                    <Button
                      style={{ width: "185px" }}
                      className={
                        `d-inline-flex align-items-center justify-content-center bg-white Lato_16_Bolder AddToButton py-5 px-10
                        ${item.app_name.split(' ').join('-').toLowerCase()}
                        Parent_${parentComponent}`
                      }
                    >
                      {
                        item.app_logo.gatsbyImageData ? (
                          <>
                            <span className='me-3'> Add to </span>
                            <GatsbyImage
                              image={item.app_logo.gatsbyImageData}
                              alt={item.app_logo.alt}
                              objectFit='contain' />
                          </>
                        ) : null
                      }
                      <span className='ms-3'>{item.app_name}</span>
                    </Button>
                  </PrismicLink>
                </div>
              )
            }
          )
        }
      </div>
    </>
  )
}

export const HeroSection: React.FunctionComponent<IHeroSectionProps> = ({ slice }) => {

  return (
    <div className="container pt-40">
      <div className={`row d-flex flex-wrap align-items-center pt-30 pt-lg-45 pt-xxxl-50 px-0 pb-0 ${heroSectionClasses.HeroSection}`}>
        <div className="hero-section-content order-1 order-lg-0 col-12 col-lg-6">

          <div className='text-white'>
            <PrismicRichText
              field={slice.primary.hero_section_title.richText}
              components={{
                heading1: (props: any) => <h1 className="Lato_65_Bolder my-20">{props.children}</h1>,
              }} />
          </div>

          <div className='mt-10 mt-lg-30'>
            <ButtonComponents items={slice.items} />
          </div>

        </div>

        <div className={`${heroSectionClasses.HeroSection_Images} order-0 order-lg-1 col-12`}>
          <GatsbyImage
            className='w-100 h-100'
            image={slice.primary.hero_section_image.gatsbyImageData}
            alt={slice.primary.hero_section_image.alt}
            objectFit='unset' />
        </div>

      </div>

      <BannerSection />

    </div>
  );
};

export const query = graphql`
  fragment HeroSectionData on PrismicPageDataBodyHeroSection {
    primary {
      hero_section_image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        alt
      }
      hero_section_subtitle
      hero_section_title {
        richText
      }
    }
    items {
      app_logo {
        gatsbyImageData(layout: FIXED, placeholder: BLURRED)
        alt
      }
      app_name
      link_to_page {
        url
        uid
        type
        link_type
      }
    }
  }
`;
import { PrismicLink, SliceZone } from '@prismicio/react';
import { sliceComponents } from 'components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useFooterData } from 'hooks';
import * as React from 'react';
import * as footerClasses from './footer.module.scss';
import soc2Logo from '../../images/security-features/soc2badgelogo.png';

interface IFooterCopyRightSectionProps {
  teamble_logo: any;
  social_media_links: any;
  copyright_description: string;
}


const FooterCopyRightSection: React.FC<IFooterCopyRightSectionProps> = (
  { teamble_logo, social_media_links, copyright_description }
) => {

  return (
    <div className={`d-flex flex-column justify-content-between mb-15 mb-xl-0 me-80`}>
      <GatsbyImage
        className='mb-10'
        objectFit='contain'
        alt={teamble_logo.alt}
        image={teamble_logo.gatsbyImageData}
      />
      <div>
        <div className="d-flex">
          {
            social_media_links.map(
              (social_media_link: any, index: number) => {
                return (
                  <PrismicLink
                    key={index}
                    className='me-12'
                    field={social_media_link.social_media_link}
                  >
                    <GatsbyImage
                      objectFit='contain'
                      alt={social_media_link.social_media_logo.alt}
                      image={social_media_link.social_media_logo.gatsbyImageData}
                    />
                  </PrismicLink>
                )
              }
            )
          }
        </div>
        <p className="mt-12 Lato_18_Regular text-white-50">
          {copyright_description}
        </p>
      </div>
    </div>
  );
};


const Footer: React.FC<{}> = () => {

  const {
    teamble_logo,
    social_media_links,
    copyright_description,
    body
  } = useFooterData();

  return (
    <footer className={`${footerClasses.Footer} py-30`}>
      <div className="container">
        <div className="d-flex flex-wrap">
          <FooterCopyRightSection
            teamble_logo={teamble_logo}
            social_media_links={social_media_links}
            copyright_description={copyright_description}
          />

          <div className={`d-flex flex-wrap justify-content-between mb-0 mb-sm-0 ${footerClasses.Footer_Nav}`}>
            <SliceZone slices={body} components={sliceComponents} />
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center mt-12">
          <div className="d-flex flex-column flex-wrap align-items-center me-30">
            <img src={soc2Logo} alt="OC 2® Type 2 Logo" />
            <p style={{ color: "gray" }} >SOC 2® Type 2</p>
          </div>
          {/* <div>
            <iframe src="https://securityscorecard.com/security-rating/badge/teamble.com"
              width="256"
              height="100"
              frameBorder="0"
            />
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;

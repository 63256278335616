import { PrismicLink } from '@prismicio/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { Button } from 'react-bootstrap';


interface IAddToButtonsProps {
  parentComponent: 'nav' | 'blue-nav' | 'do-it-all-section' | 'add-to-page';
  data: any;
  addToText?: boolean;
  className?: string;
}

const AddToButtons: React.FunctionComponent<IAddToButtonsProps> = (
  { parentComponent, data, addToText, className }
) => {

  return (
    <>
      {
        data.map(
          (item: any, index: number) => {
            return (
              <li key={index}>
                <PrismicLink field={item.link_to_page} className={`nav-link p-0 ${className}`}>
                  <Button
                    className={
                      `d-inline-flex align-items-center bg-white Lato_16_Bolder AddToButton
                      ${parentComponent === 'do-it-all-section' ? 'py-8 px-24' : 'py-6 px-12'}
                      ${item.app_name.split(' ').join('-').toLowerCase()}
                      Parent_${parentComponent}`
                    }
                  >
                    {
                      item.app_logo.gatsbyImageData ? (
                        <>
                          {
                            addToText &&
                            <span className='me-3'> Add to </span>
                          }
                          <GatsbyImage
                            image={item.app_logo.gatsbyImageData}
                            alt={item.app_logo.alt}
                            objectFit='contain' />
                        </>
                      ) : null
                    }
                    <span className='ms-3'>{item.app_name}</span>
                  </Button>
                </PrismicLink>
              </li>
            )
          }
        )
      }
    </>
  );
};

export default AddToButtons;

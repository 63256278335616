import { PrismicRichText } from '@prismicio/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import moment from 'moment';
import * as blogDetailsSectionClasses from './blogDetailsSection.module.scss';


interface IBlogDetailsSectionProps {
  slice: any;
  context: any;
}

export const BlogDetailsSection: React.FunctionComponent<IBlogDetailsSectionProps> = ({ slice, context }) => {
  return (
    <section className='section'>
      <div className='container'>

        <GatsbyImage
          style={{ maxWidth: '800px' }}
          className='mx-auto'
          image={slice.primary.feature_image.gatsbyImageData}
          alt={slice.primary.feature_image.alt}
          objectFit='contain' />

        <div className={`${blogDetailsSectionClasses.BlogContent_Container} mx-auto`}>
          <p className='Lato_16_Regular Font_Color_Error my-8'> {slice.primary.blog_category} </p>

          <PrismicRichText
            field={slice.primary.blog_title.richText}
            components={{
              heading1: (props: any) =>
              (
                <h1 className='Lato_30_Bold Font_Color_Primary mb-15'>
                  {props.children}
                </h1>
              ),
            }} />

          <p className="Lato_14_Regular Font_Color_Quaternary mb-6">
            {moment(context.published_at, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
          </p>

          <p className="Lato_18_Regular Font_Color_Primary mb-6">
            <PrismicRichText
              field={slice.primary.blog_content.richText}
              components={{
                image: (props: any) => {

                  if (props.node.linkTo) {
                    return (
                      <a href={props.node.linkTo.url} target='_blank'>
                        <img className='mw-100' src={props.node.url} />
                      </a>
                    );
                  }

                  return (
                    <img className='mw-100' src={props.node.url} />
                  );
                }
              }}
            />
          </p>
        </div>
      </div>
    </section>
  );
};

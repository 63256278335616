import { graphql, useStaticQuery } from 'gatsby';

export const useFooterData = () => {

  return useStaticQuery(
    graphql`
      {
        prismicFooter {
          data {
            teamble_logo {
              gatsbyImageData(layout: FIXED, placeholder: BLURRED)
              alt
            }
            social_media_links {
              social_media_link {
                url
                link_type
              }
              social_media_logo {
                gatsbyImageData(layout: FIXED, placeholder: BLURRED)
                alt
              }
            }
            copyright_description
            body {
              ... on PrismicFooterDataBodyFooterLinks {
                slice_type
              }
              ...FooterLinksData
            }
          }
        }
      }
    `
  ).prismicFooter.data;

};
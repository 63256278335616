import { SliceZone } from "@prismicio/react";
import { Footer, Header, sliceComponents } from "components";
import { useHeroSectionAndCustomerLogoData } from "hooks";
import * as React from "react";
import CookieBanner from "./cookieBanner/cookieBanner";

interface ILayoutProps {
  children: React.ReactNode;
  isHomePage?: boolean;
}

const Layout: React.FunctionComponent<ILayoutProps> = (
  { children, isHomePage }
) => {

  const sectionData = useHeroSectionAndCustomerLogoData(!!isHomePage);


  return (
    <>
      <div className='position-relative overflow-hidden'>
        <Header isHomePage={isHomePage} />
        {
          isHomePage && sectionData.body ? (
            <div className="pb-160">
              <SliceZone
                slices={sectionData.body}
                components={sliceComponents}
                defaultComponent={() => null} />
            </div>
          ) : null
        }
      </div>
      <main className="pt-40 position-relative">{children}</main>
      <Footer />
      <CookieBanner />
    </>
  )
}


export default Layout

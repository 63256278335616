import { PrismicRichText } from '@prismicio/react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import * as howItWorksSectionClasses from './howItWorksSection.module.scss';

interface IHowItWorksSectionProps {
  slice: any;
}

interface IHowItWorksSectionRowProps {
  rowData: any;
}

const HowItWorksTextContent: React.FunctionComponent<IHowItWorksSectionRowProps> = ({ rowData }) => {

  return (
    <div
      className={`w-100 ${howItWorksSectionClasses.HowItWorksSection_Item_TextContent}`}
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <p className="m-0 mb-16 Lato_65_Bolder Font_Color_Quinary">
        {rowData.subsection_title}
      </p>
      <PrismicRichText
        field={rowData.subsection_description.richText}
        components={{
          paragraph: (props: any) => <p className="m-0 Lato_24_Regular Font_Color_Quaternary text-start">{props.children}</p>,
          list: (props: any) => <ul className='list-unstyled'> {props.children} </ul>,
          listItem: (props: any) => <li className="m-0 Lato_24_Regular Font_Color_Quaternary text-start">{props.children}</li>
        }} />
    </div>
  )

};


const HowItWorksImageContent: React.FunctionComponent<IHowItWorksSectionRowProps> = ({ rowData }) => {
  return (
    <div
      className={`${howItWorksSectionClasses.HowItWorksSection_Item_ImageContent}`}
      data-aos={rowData.image_alignment === 'Left' ? "fade-right" : "fade-left"}
      data-aos-duration="2000"
    >
      <GatsbyImage
        image={rowData.subsection_image.gatsbyImageData}
        alt={rowData.subsection_image.alt}
        objectFit='contain' />
    </div>
  );
}


const HowItWorksSectionRow: React.FunctionComponent<IHowItWorksSectionRowProps> = ({ rowData }) => {

  return (
    <li
      className={
        `d-flex align-items-center justify-content-between section
        ${howItWorksSectionClasses.HowItWorksSection_Item}
        ${rowData.image_alignment === 'Right' ? 'flex-column' : 'flex-column-reverse'} flex-lg-row`
      }
    >

      {
        rowData.image_alignment === 'Right' ?
          (
            <>
              <HowItWorksTextContent rowData={rowData} />
              <HowItWorksImageContent rowData={rowData} />
            </>
          ) :
          (
            <>
              <HowItWorksImageContent rowData={rowData} />
              <HowItWorksTextContent rowData={rowData} />
            </>
          )
      }

    </li >
  );
}


export const HowItWorksSection: React.FunctionComponent<IHowItWorksSectionProps> = ({ slice }) => {

  return (
    <section
      className={`section overflow-hidden ${howItWorksSectionClasses.HowItWorksSection}`}
      id="how-it-works-section"
    >

      <div className="position-relative container">
        <ul className="list-unstyled how-it-works-list">

          {
            slice.items.map(
              (rowData: any, index: number) => {
                return (
                  <HowItWorksSectionRow key={index} rowData={rowData} />
                )
              }
            )
          }

        </ul>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment HowItWorksSectionData on PrismicPageDataBodyHowItWorksSection {
    items {
      image_alignment
      subsection_title
      subsection_description {
        richText
      }
      subsection_image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        alt
      }
    }
  }
  fragment TeamblePageHowItWorksSectionData on PrismicTeamblePageDataBodyHowItWorksSection {
    items {
      image_alignment
      subsection_title
      subsection_description {
        richText
      }
      subsection_image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        alt
      }
    }
  }
`;
import { PrismicRichText } from '@prismicio/react';
import { graphql } from 'gatsby';
import * as React from 'react';

interface IFaqSectionProps {
  slice: any;
}

export const FaqSection: React.FunctionComponent<IFaqSectionProps> = ({ slice }) => {
  return (
    <section className="py-30 section">
      <div className="container">

        <PrismicRichText
          field={slice.primary.title.richText}
          components={{
            heading2: (props: any) => <h2 className="Lato_45_Regular Font_Color_Primary text-center mb-30">{props.children}</h2>,
          }} />

        {
          slice.items.map(
            (item: any, index: number) => {

              return (
                <div key={index} className='mb-15'>
                  <PrismicRichText
                    field={item.title.richText}
                    components={{
                      heading3: (props: any) => <h3 className="Lato_30_Bold Font_Color_Primary">{props.children}</h3>,
                    }}
                  />
                  <PrismicRichText
                    field={item.text.richText}
                    components={{
                      paragraph: (props: any) => <p className="Montserrat_18_Medium Font_Color_Primary mb-5">{props.children}</p>,
                    }}
                  />
                </div>

              );
            }
          )
        }

        {/* <div className="md-display-2" style="color: #354042; margin: 4rem 0;">
          Pricing FAQs
        </div>
        <div className="title-left">
          Do you provide customer support?
        </div>
        <div className="section-text" style="text-align: left;">
          <div>
            Team Plan include Basic Support with in app chat, email and phone support.
          </div>
          <div>
            Enterprise Plan include Premium Support with dedicated account management,
            live chat, phone and email support, implementation consultations, and training sessions.
          </div>
        </div>
        <div className="title-left" style="margin-top: 2rem;">
          Is the Base Plan a free trial for a certain period of time?
        </div>
        <div className="section-text" style="text-align: left;">
          No, the Base Plan is a free plan forever and has no time limit.
          You can always upgrade to a Team or Enterprise Plan if you develop the need for more advanced features.
        </div> */}
      </div>
    </section>
  );
};


export const query = graphql`
  fragment FaqSectionData on PrismicTeamblePageDataBodyFaqSection {
    primary {
      title {
        richText
      }
    }
    items {
      text {
        richText
      }
      title {
        richText
      }
    }
  }
`;
// extracted by mini-css-extract-plugin
export var QuoteSection = "quoteSection-module--QuoteSection--z4T4F";
export var QuoteSection_QuoteText = "quoteSection-module--QuoteSection_QuoteText--CRXZh";
export var Lato_14_Regular = "quoteSection-module--Lato_14_Regular--iLGu3";
export var Lato_16_Regular = "quoteSection-module--Lato_16_Regular--fm6zo";
export var Lato_16_Bolder = "quoteSection-module--Lato_16_Bolder--VOGNp";
export var Lato_18_Regular = "quoteSection-module--Lato_18_Regular--JpPbR";
export var Lato_18_Bold = "quoteSection-module--Lato_18_Bold--tkB1q";
export var Lato_20_Regular = "quoteSection-module--Lato_20_Regular--vQbV3";
export var Lato_20_Bold = "quoteSection-module--Lato_20_Bold--Pvv9P";
export var Lato_20_Bolder = "quoteSection-module--Lato_20_Bolder--a3zzk";
export var Montserrat_16_Regular = "quoteSection-module--Montserrat_16_Regular--XyKQK";
export var Montserrat_18_Medium = "quoteSection-module--Montserrat_18_Medium--3nyW2";
export var Montserrat_20_Medium = "quoteSection-module--Montserrat_20_Medium--mVr94";
export var Lato_20_Semibold = "quoteSection-module--Lato_20_Semibold--CnFY6";
export var Lato_22_Semibold = "quoteSection-module--Lato_22_Semibold--Wfk9N";
export var Lato_24_Regular = "quoteSection-module--Lato_24_Regular--jr1BM";
export var Lato_24_Semibold = "quoteSection-module--Lato_24_Semibold--hSKQ1";
export var Lato_28_Semibold = "quoteSection-module--Lato_28_Semibold--5h2vm";
export var Lato_32_Bolder = "quoteSection-module--Lato_32_Bolder--MCSKC";
export var Lato_30_Bold = "quoteSection-module--Lato_30_Bold--Hh2jB";
export var Lato_36_Bold = "quoteSection-module--Lato_36_Bold--EzjLD";
export var Lato_40_Bolder = "quoteSection-module--Lato_40_Bolder--Zigh8";
export var Lato_45_Regular = "quoteSection-module--Lato_45_Regular--MQld8";
export var Lato_52_Bolder = "quoteSection-module--Lato_52_Bolder--VhlDC";
export var Lato_65_Bolder = "quoteSection-module--Lato_65_Bolder--km+l2";
export var QuoteSection_Content = "quoteSection-module--QuoteSection_Content--P8c+v";
export var QuoteSection_Row = "quoteSection-module--QuoteSection_Row--9oTbY";
export var Quote_Professor_Description = "quoteSection-module--Quote_Professor_Description--onK1U";
export var Professor_Image = "quoteSection-module--Professor_Image--p2cDi";
export var QuoteSection_Quote_Text = "quoteSection-module--QuoteSection_Quote_Text--gGlIZ";
export var Quote_Professor_Name = "quoteSection-module--Quote_Professor_Name--QlLas";
export var Quote_Professor_Title = "quoteSection-module--Quote_Professor_Title--eNOcO";
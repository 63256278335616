import { graphql, useStaticQuery } from "gatsby";

export const useHeroSectionAndCustomerLogoData = (isHomePage: boolean) => {

  if (!isHomePage) {
    return null;
  }

  return useStaticQuery(
    graphql`
      {
        prismicPage {
          data {
            body {
              ... on PrismicPageDataBodyHeroSection {
                slice_type
              }
              ... on PrismicPageDataBodyCustomerLogos {
                slice_type
              }
              ...HeroSectionData
              ...CustomerLogoData
            }
          }
        }
      }
    `
  ).prismicPage.data;

};
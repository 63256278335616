import { PrismicRichText } from '@prismicio/react';
import { AddToButtons } from 'components';
import { graphql } from 'gatsby';
import * as React from 'react';
import * as doItAllSectionClasses from './doItAllSection.module.scss';

interface IDoItAllSectionProps {
  slice: any;
}

export const DoItAllSection: React.FunctionComponent<IDoItAllSectionProps> = ({ slice }) => {
  return (
    <section className={`section pb-120 ${doItAllSectionClasses.DoItAllSection}`}>
      <div className="container">
        <div
          className={
            `d-flex justify-content-between flex-wrap align-items-center text-white px-42 py-36
            ${doItAllSectionClasses.DoItAllSection_Content}`
          }
        >
          <div>
            <PrismicRichText
              field={slice.primary.title.richText}
              components={{
                heading2: (props: any) => <h2 className="mt-0 mb-16 Lato_52_Bolder">{props.children}</h2>,
              }}
            />
            <p className='m-0 mb-6 Lato_24_Regular'>
              {slice.primary.section_description}
            </p>
          </div>
          <ul className={`list-unstyled flex-shrink-0 d-flex flex-wrap ${doItAllSectionClasses.Do_It_Buttons}`}>
            <AddToButtons
              data={slice.items}
              parentComponent='do-it-all-section'
              className='me-12' />
          </ul>
        </div>
      </div >
    </section >
  );
};


export const query = graphql`
  fragment DoItAllSectionData on PrismicPageDataBodySlackMsTeamsSection {
    primary {
      section_description
      title {
        richText
      }
    }
    items {
      app_name
      app_logo {
        gatsbyImageData(layout: FIXED, placeholder: BLURRED)
        alt
      }
      link_to_page {
        url
        uid
        type
        link_type
      }
    }
  }
`;
import { PrismicRichText } from '@prismicio/react';
import { graphql } from 'gatsby';
import * as React from 'react';
import * as addressSectionClasses from './addressSection.module.scss';

interface IAddressSectionProps {
  slice: any;
}

export const AddressSection: React.FunctionComponent<IAddressSectionProps> = ({ slice }) => {
  return (
    <section className='py-30 section'>
      <div className='container'>
        <div className="row">
          <div className="col-12 col-lg-6 m-auto">
            <div className={`${addressSectionClasses.Address_Box} p-10`}>
              <PrismicRichText
                field={slice.primary.address.richText}
                components={{
                  paragraph: (props: any) => <p className="Lato_18_Regular Montserrat_16_Regular mb-3">{props.children}</p>,
                }} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment AddressSectionData on PrismicTeamblePageDataBodyAddress {
    primary {
      address {
        richText
      }
    }
  }
`;
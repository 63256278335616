// extracted by mini-css-extract-plugin
export var CallToActionSection = "callToActionSection-module--CallToActionSection--u2vkN";
export var Intro_Header = "callToActionSection-module--Intro_Header--l2Jam";
export var Header_Text = "callToActionSection-module--Header_Text--2UHiJ";
export var Subheader_Text = "callToActionSection-module--Subheader_Text--g1DSY";
export var text = "callToActionSection-module--text--L2R9t";
export var sectionContainer = "callToActionSection-module--section-container--qu5c-";
export var sectionTitle = "callToActionSection-module--section-title--JNEhy";
export var sectionText = "callToActionSection-module--section-text--2ar0B";
export var blueContainer = "callToActionSection-module--blue-container--lbHGk";
export var whiteContainer = "callToActionSection-module--white-container--0Wm-G";
export var curvyShadow = "callToActionSection-module--curvy-shadow--GLLIF";
export var landingImage = "callToActionSection-module--landing-image--jY4eO";
export var slackLogo = "callToActionSection-module--slack-logo--Uc8kW";
export var teambleLogo = "callToActionSection-module--teamble-logo--gFDD2";
export var introHeader = "callToActionSection-module--intro-header--ycpNc";
export var iconSm = "callToActionSection-module--icon-sm---0Hfw";
export var slackTeambleLogo = "callToActionSection-module--slack-teamble-logo--Etqls";
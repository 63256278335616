import { PrismicLink } from '@prismicio/react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useNavigationData } from 'hooks';
import * as React from 'react';
import { Button, Container, Nav, Navbar, Row } from 'react-bootstrap';
import AddToButtons from '../addToButtons/addToButtons';
import * as navigationBarClasses from './navigationBar.module.scss';


interface INavigationBarProps {
  isHomePage?: boolean;
}


const NavigationBarProps: React.FunctionComponent<INavigationBarProps> = (
  { isHomePage }
) => {


  const [isWindowScrollOnTop, setIsWindowScrollOnTop] = React.useState<boolean>(true);


  const {
    home_page_background_image,
    teamble_white_logo,
    teamble_gradient_logo,
    links_group,
    add_to_app_links
  } = useNavigationData();


  const updateIsWindowScrollOnTop = (): void => {
    setIsWindowScrollOnTop(window.scrollY === 0);
  };


  React.useEffect(
    () => {

      window.addEventListener('scroll', updateIsWindowScrollOnTop);

      return () => {
        window.removeEventListener('scroll', updateIsWindowScrollOnTop);
      };

    },
    []
  );


  return (
    <>
      {
        isHomePage ? (
          <GatsbyImage
            objectFit='unset'
            className={`w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0 ${navigationBarClasses.BgImage}`}
            alt={home_page_background_image.alt}
            image={home_page_background_image.gatsbyImageData}
          />
        ) : null
      }

      <Navbar
        collapseOnSelect
        bg={isWindowScrollOnTop && isHomePage ? "transparent" : "white"}
        expand="lg"
        className={`${navigationBarClasses.NavBar} d-block pt-0 pb-8`}
        fixed='top'
      >

        <div className='Banner_Background py-5 w-100 justify-content-center align-items-center d-none d-sm-flex'>
          <p className='Font_Color_White m-0'>
            <span className='Lato_20_Bolder'> 🤖 teamble AI {' '}</span>
            <span className='Lato_20_Semibold'>is here to train your managers how to give feedback</span>
          </p>
          <a href='https://sendspark.com/share/2my5cigsu9ka2gzid2gt3pwy2av9oj04' target='_blank'>
            <Button className='ms-16 rounded-pill border-white border-2 bg-transparent text-white Lato_18_Bold px-16'>
              Learn more
            </Button>
          </a>
        </div>

        <Container className={`pt-8`}>

          <Navbar.Brand>
            <Link to='/'>
              <GatsbyImage
                image={isWindowScrollOnTop && isHomePage ? teamble_white_logo.gatsbyImageData : teamble_gradient_logo.gatsbyImageData}
                alt={isWindowScrollOnTop && isHomePage ? teamble_white_logo.alt : teamble_gradient_logo.alt}
                objectFit='contain' />
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav" className={`${navigationBarClasses.NavBarCollapse}`}>
            <Nav as='ul' className="me-auto">
              {
                links_group.map(
                  (link: any, index: number) => {
                    return (
                      <li key={index}>

                        {
                          link.fragment_id ?
                            (
                              <Nav.Link
                                className={
                                  `ms-21 px-0 position-relative
                                  ${isWindowScrollOnTop && isHomePage ? `${navigationBarClasses.NavLink_BlueHeader}` : ''}
                                  ${navigationBarClasses.NavLink}`
                                }
                                href={`${process.env.GATSBY_DOMAIN}#${link.fragment_id}`}>{link.link_label}</Nav.Link>
                            ) : (
                              <PrismicLink field={link.link}
                                className={
                                  `nav-link ms-21 px-0 position-relative
                                    ${isWindowScrollOnTop && isHomePage ? `${navigationBarClasses.NavLink_BlueHeader}` : ''}
                                    ${navigationBarClasses.NavLink}`
                                }
                              >
                                {link.link_label}
                              </PrismicLink>
                            )
                        }
                      </li>
                    )
                  }

                )
              }
            </Nav>
            <Nav as='ul'>
              <AddToButtons
                data={add_to_app_links}
                parentComponent={isWindowScrollOnTop && isHomePage ? 'blue-nav' : 'nav'}
                className='ms-12' />
            </Nav>
          </Navbar.Collapse>

        </Container>
      </Navbar>
    </>
  );
};

export default NavigationBarProps;

import { PrismicRichText } from '@prismicio/react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import './customerLogosSection.scss';


interface ICustomerLogosSectionProps {
  slice: any;
}

export const CustomerLogosSection: React.FunctionComponent<ICustomerLogosSectionProps> = ({ slice }) => {
  return (
    <div className={`text-center mt-30 TeambleClients container`}>

      <PrismicRichText
        field={slice.primary.eyebrow_headline.richText}
        components={{
          heading2: (props: any) => <h2 className="Lato_40_Bolder text-white mb-16">{props.children}</h2>,
        }} />

      <div className={`d-flex flex-wrap justify-content-center p-0 px-lg-70 align-items-center TeambleClients_Logos`}>

        {
          slice.items.map(
            (item: any, index: number) => {
              return (
                <GatsbyImage
                  className={`flex-shrink-0 mx-12 my-15 ${item.logo.alt.split(' ').join('-')}`}
                  key={index}
                  image={item.logo.gatsbyImageData}
                  alt={item.logo.alt}
                  objectFit='contain'
                />
              )
            }
          )
        }

      </div>
    </div>
  );
};

export const query = graphql`
  fragment CustomerLogoData on PrismicPageDataBodyCustomerLogos {
    primary {
      eyebrow_headline {
        richText
      }
    }
    items {
      logo {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        alt
      }
    }
  }
`;


import { Link } from 'gatsby';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import './cookieBanner.scss';

interface ICookieBannerProps {
}

const CookieBanner: React.FunctionComponent<ICookieBannerProps> = () => {

  const [areCookiesConfirmed, setAreCookiesConfirmed] = React.useState<boolean>(true);


  React.useEffect(
    () => {
      setAreCookiesConfirmed(
        !!localStorage.getItem("cookiesConfirmed")
      );
    },
    []
  );


  const handleAcceptCookies = () => {
    setAreCookiesConfirmed(true);
    localStorage.setItem("cookiesConfirmed", "true");
  };


  return (
    <>
      {
        !areCookiesConfirmed ? (
          <div className="cookie-confirmation-container">
            <div className="inner-container">
              <div>
                <p>This website uses cookies to improve your experience.</p>
                <p>
                  See our {' '}
                  <Link to="/privacy-policy" target="_blank">
                    Privacy Policy
                  </Link>
                  {' '} to learn more.
                </p>
              </div>
              <Button className='Font_Color_Primary' onClick={handleAcceptCookies} >Accept</Button>
            </div>
          </div>
        ) : null
      }
    </>
  );
};

export default CookieBanner;

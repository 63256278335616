import { NavigationBar } from "components";
import * as React from "react";

interface IHeaderProps {
  isHomePage?: boolean;
}

const Header: React.FunctionComponent<IHeaderProps> = (
  { isHomePage }
) => {

  // React.useEffect(() => {

  //   (function () {
  //     var reb2b = ((window as any).reb2b = (window as any).reb2b || []);
  //     if (reb2b.invoked) return;
  //     reb2b.invoked = true;

  //     reb2b.methods = ["identify", "collect"];
  //     reb2b.factory = function (method: any) {
  //       return function () {
  //         var args = Array.prototype.slice.call(arguments);
  //         args.unshift(method);
  //         reb2b.push(args);
  //         return reb2b;
  //       };
  //     };

  //     for (var i = 0; i < reb2b.methods.length; i++) {
  //       var key = reb2b.methods[i];
  //       reb2b[key] = reb2b.factory(key);
  //     }

  //     reb2b.load = function (key: string) {
  //       var script = document.createElement("script");
  //       script.type = "text/javascript";
  //       script.async = true;
  //       script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/Z6PVLH5MV56R.js.gz";
  //       var first = document.getElementsByTagName("script")[0];
  //       if (first?.parentNode) {
  //         first.parentNode.insertBefore(script, first);
  //       }
  //     };

  //     reb2b.SNIPPET_VERSION = "1.0.1";
  //     reb2b.load("Z6PVLH5MV56R");
  //   })();

  // }, []);

  return (
    <header>
      <NavigationBar isHomePage={isHomePage} />
    </header>
  )
};

export default Header;

import { PrismicLink } from '@prismicio/react';
import { graphql } from 'gatsby';
import * as React from 'react';


interface FooterLinks {
  slice: any;
}


export const FooterLinks: React.FunctionComponent<FooterLinks> = ({ slice }) => {
  return (
    <ul className="list-unstyled m-0 mb-10 mb-lg-0">
      <li className="m-0 Lato_18_Bold text-white">{slice.primary.group_title}</li>
      {
        slice.items.map(
          (item: any, index: number) => {
            return (
              <li key={index} className="mt-12 Lato_18_Regular text-white">
                {
                  item.is_javascript_link ? (
                    <a
                      className='text-white text-decoration-none'
                      href={item.link.url.split('https://')[1]}>{item.link_label}</a>
                  ) :
                    (
                      <>
                        {
                          item.fragment_id ?
                            (
                              <a
                                className='text-white text-decoration-none'
                                href={`${process.env.GATSBY_DOMAIN}#${item.fragment_id}`}>{item.link_label}</a>
                            ) :
                            (
                              <PrismicLink field={item.link} className='text-white text-decoration-none'>
                                {item.link_label}
                              </PrismicLink>
                            )
                        }
                      </>
                    )
                }

              </li>
            )
          }
        )
      }
    </ul>
  );
};

export const query = graphql`
  fragment FooterLinksData on PrismicFooterDataBodyFooterLinks {
    primary {
      group_title
    }
    items {
      is_javascript_link
      fragment_id
      link_label
      link {
        url
        type
        link_type
        uid
      }
    }
  }
`;